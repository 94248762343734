import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { paragraphStyler } from './index';
import styled from 'src/style';
import { useMediaQueries } from '../../utils/MediaQueries';

/**
 * @type React.FunctionComponent<{ text: string, hero?: boolean}>
 */

export const LinesEllipsisStyled = styled(LinesEllipsis)(paragraphStyler);

const ParagraphEllipsis = ({ text, hero }) => {
  const mqs = useMediaQueries();

  if (hero && mqs.desktopUp) {
    return (
      <LinesEllipsisStyled
        css={{ padding: 0, whiteSpace: 'pre-wrap' }}
        maxLine="4"
        ellipsis="..."
        trimRight
        basedOn="letters"
        text={text}
      />
    );
  }
  if (hero && mqs.tabletLandscapeUp) {
    return (
      <LinesEllipsisStyled
        css={{ padding: 0, whiteSpace: 'pre-wrap' }}
        maxLine="2"
        ellipsis="..."
        trimRight
        basedOn="letters"
        text={text}
      />
    );
  }
  if ((hero && mqs.tabletPortraitUp) || (hero && mqs.phoneOnly)) {
    return (
      <LinesEllipsisStyled
        css={{ paddingTop: 20, paddingBottom: 20, whiteSpace: 'pre-wrap' }}
        maxLine="3"
        ellipsis="..."
        trimRight
        basedOn="letters"
        text={text}
      />
    );
  }
  if (mqs.phoneOnly && hero === false) {
    return (
      <LinesEllipsisStyled
        css={{ paddingTop: 20, paddingBottom: 20, whiteSpace: 'pre-wrap' }}
        maxLine="4"
        ellipsis="..."
        trimRight
        basedOn="letters"
        text={text}
      />
    );
  }
  if (mqs.tabletPortraitUp && hero === false) {
    return (
      <LinesEllipsisStyled
        css={{ paddingTop: 20, paddingBottom: 20, whiteSpace: 'pre-wrap' }}
        maxLine="3"
        ellipsis="..."
        trimRight
        basedOn="letters"
        text={text}
      />
    );
  }
  if (mqs.tabletLandscapeUp && hero === false) {
    return (
      <LinesEllipsisStyled
        css={{ paddingTop: 20, paddingBottom: 20, whiteSpace: 'pre-wrap' }}
        maxLine="2"
        ellipsis="..."
        trimRight
        basedOn="letters"
        text={text}
      />
    );
  } else {
    return null;
  }
};

export default ParagraphEllipsis;
