import React from 'react';
import { useMediaQueries } from 'src/utils/MediaQueries';
import { Card } from 'src/components/Blog/index';

const RelatedPosts = ({ edges, isBlogPost, currentPost }) => {
  const mqs = useMediaQueries();

  const handlePosts = () => {
    if (edges) {
      if (!mqs.tabletLandscapeUp || !mqs.desktopUp) {
        return edges
          .filter(
            (edge) => edge.node.frontmatter.published === true && edge.node.id !== currentPost.id
          )
          .map((edge) => <Card key={edge.node.id} post={edge.node} isBlogPost={isBlogPost} />)
          .slice(0, 2);
      } else {
        return edges
          .filter(
            (edge) => edge.node.frontmatter.published === true && edge.node.id !== currentPost.id
          )
          .map((edge) => <Card key={edge.node.id} post={edge.node} isBlogPost={isBlogPost} />)
          .slice(0, 3);
      }
    }
  };

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        flexWrap: 'wrap',
      }}
    >
      {handlePosts()}
    </div>
  );
};
export default RelatedPosts;
