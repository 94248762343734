import React from 'react';
import { TagBtn } from './elements';
import { H1 } from '../Typography';
import { mediaQueries } from 'src/style/breakpoints';

const Tags = ({ TagsItems, handleOnClick, tagSelected }) => {
  return (
    <ul
      css={{
        display: 'none',
        [mediaQueries.tabletPortraitUp]: {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          margin: '50px 0px 30px 0px',
          justifyContent: 'center',
        },
      }}
    >
      {TagsItems.map((item, index) => {
        return (
          <TagBtn
            key={item}
            tagSelected={tagSelected === item}
            onClick={() => handleOnClick(item, index)}
          >
            {item === 'Apprendre à coder' ? <H1>{item}</H1> : item}
          </TagBtn>
        );
      })}
    </ul>
  );
};
export default Tags;
