import React from 'react';
import colors from 'src/style/colors';
import { CTASectionSubtitle } from '../Typography';
import { Footer, FooterText, Tag } from './elements';
import { Button } from '../Button';
import ParagraphEllipsis from '../Typography/ParagraphEllipsis';
import Theme from '../Theme';

const CardOverlay = ({ post, isBlogPost }) => {
  return (
    <Theme theme="primary">
      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          padding: '25px 30px',
          backgroundColor: isBlogPost ? colors.purpleDark : colors.purple,
          opacity: 0,
          transition: '.3s linear',
          borderRadius: 10,
          cursor: 'pointer',
          border: isBlogPost && `1px solid ${colors.purpleDark} `,
          boxShadow: 'rgba(0, 0, 0, 0.07) 1px 2px 15px',
          '&:hover': {
            opacity: 1,
          },
        }}
      >
        <Tag css={{ color: colors.white }}>{post.frontmatter.tags}</Tag>
        <CTASectionSubtitle
          css={{
            color: colors.white,
          }}
        >
          {post.frontmatter.title}
        </CTASectionSubtitle>

        <Footer>
          <FooterText css={{ color: colors.white }}>{post.frontmatter.date}</FooterText>
          <div css={{ width: 30 }} />
          <FooterText css={{ color: colors.white }}>— {post.frontmatter.author}</FooterText>
        </Footer>

        <ParagraphEllipsis hero={false} text={post.frontmatter.preview} />

        <div theme="primary">
          <Button
            isSmall={true}
            to={post.fields.slug}
            css={{ backgroundColor: colors.white, color: colors.purple }}
          >
            Lire l'article
          </Button>
        </div>
      </div>
    </Theme>
  );
};

export default CardOverlay;
