import styled from 'src/style';
import colors from 'src/style/colors';
import { Sentence, TextContainer } from '../Typography';
import { mediaQueries } from 'src/style/breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

export const Footer = styled.div({
  position: 'absolute',
  bottom: 20,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  [mediaQueries.tabletLandscapeUp]: {
    bottom: 20,
  },
});

export const HeroPostImage = styled(GatsbyImage)({
  objectFit: 'cover',
  width: '100%',
  height: 250,
  [mediaQueries.tabletPortraitUp]: {
    height: 300,
  },
  [mediaQueries.tabletLandscapeUp]: {
    width: '100%',
    height: '100%',
  },
});

export const HeroCardContainer = styled(Link)({
  textDecoration: 'none',
  backgroundColor: colors.white,
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: 'auto',
  overflow: 'hidden',
  boxShadow: 'rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px',
  transition: 'all 0.5s ease',
  ':hover': {
    boxShadow: 'rgba(39, 44, 49, 0.07) 8px 28px 50px, rgba(39, 44, 49, 0.04) 1px 6px 12px',
    transition: 'all 0.4s ease',
    transform: 'translate3D(0, -1px, 0) scale(1.01)',
  },
  [mediaQueries.tabletPortraitUp]: {
    height: 'auto',
  },
  [mediaQueries.tabletLandscapeUp]: {
    flexDirection: 'row',
    height: 560,
  },
});

export const CardContainer = styled(Link)({
  textDecoration: 'none',
  flex: 1,
  height: 'auto',
  WebkitMaskImage: '-webkit-radial-gradient(white, black)',
  borderRadius: 10,
  [mediaQueries.tabletLandscapeUp]: {
    height: 430,
  },
  [mediaQueries.desktopUp]: {
    height: 455,
  },
});

export const Tag = styled.h3({
  textTransform: 'uppercase',
  fontSize: 12,
  letterSpacing: 0.2,
  fontWeight: 'bold',
  color: colors.purple,
  paddingBottom: 8,
  paddingLeft: 1,
  [mediaQueries.phoneOnly]: {
    fontSize: 11,
    paddingBottom: 4,
  },
  [mediaQueries.tabletLandscapeUp]: {
    paddingBottom: 0,
  },
});

export const TagBtn = styled.a(
  {
    borderRadius: '50px',
    textDecoration: 'none',
    marginRight: 10,
    marginTop: 10,
    padding: '12px 25px',
    transition: '.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.purple,
      border: `1px solid ${colors.purple}`,
    },
  },
  ({ tagSelected }) => ({
    backgroundColor: tagSelected ? colors.purple : colors.white,
    color: tagSelected ? colors.white : 'grey',
    border: tagSelected ? `1px solid ${colors.purple}` : `1px solid grey`,
  })
);

export const FooterText = styled(Sentence)({
  color: 'grey',
  fontSize: 13,
  [mediaQueries.phoneOnly]: {
    fontSize: 13,
  },
  '& a': {
    color: 'grey',
    '&:hover': { color: 'black', transition: 'all 0.2s ease-in-out' },
  },
});

export const CardContent = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '20px 25px',
  },
  ({ hero }) => ({
    [mediaQueries.tabletLandscapeUp]: {
      justifyContent: 'space-between',
      padding: hero ? '30px 40px' : '25px 30px',
    },
  })
);

export const CenteredContentBlog = styled.div({
  margin: '0 auto',
  maxWidth: 770,
  width: '100%',
  display: 'block',
  paddingRight: 20,
  paddingLeft: 20,
  boxSizing: 'border-box',
  [mediaQueries.phoneOnly]: {
    marginTop: 45,
    paddingRight: 5,
    paddingLeft: 5,
  },
  [mediaQueries.tabletPortraitUp]: {
    marginTop: 70,
  },
  [mediaQueries.tabletLandscapeUp]: {
    marginTop: 80,
  },
});

export const ContentBlog = styled(TextContainer)({
  fontSize: 19,
  lineHeight: '33px',
  color: colors.purpleDark,
  '& a': {
    color: colors.purpleDark,
    '&:hover': { color: colors.yellow, transition: 'all 0.2s ease-in-out' },
  },
  // '& ul': { listStyleType: 'none', marginTop: 25, color: '#000' },
  '& ol, ul': { margin: '10px 0 25px 35px', color: '#000' },
  '& ul li, ol li': { marginBottom: 10 },
  // summary list
  '& ul:first-of-type': { listStyleType: 'disc', marginLeft: 20, color: colors.yellow },
  '& ul:first-of-type>li:not(:last-child)': {
    marginBottom: 10,
  },
  '& ul:first-of-type>li>a': {
    color: colors.yellow,
    textDecoration: 'none',
  },
  '& h2': {
    padding: '60px 0 20px 0',
    position: 'relative',
  },
  '& h2:before': {
    content: "''",
    width: 25,
    height: 8,
    backgroundColor: colors.yellow,
    display: 'inline-block',
    marginRight: 15,
    marginBottom: 6,
    borderRadius: 5,
  },
  '& h3': {
    padding: '30px 0 5px 0',
  },
  '& p': { padding: '10px 0 10px 0', textAlign: 'justify' },
  '& a[name]': {
    position: 'absolute',
    top: -30,
    left: 0,
    display: 'inline-block',
  },
  '& code': {
    backgroundColor: '#F6F8FA',
    fontSize: 16,
    color: 'grey',
    marginTop: 10,
    marginBottom: 20,
    padding: '10px 15px',
    borderRadius: 5,
    maxWidth: 770,
    display: 'block',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    overflow: 'auto',
  },
  '& img': {
    width: '100%',
  },
  '& blockquote': {
    color: colors.darkGrey,
    fontSize: '1.1em',
    padding: '20px 0 20px 20px',
    fontStyle: 'italic',
    '&::before': {
      content: '"« "',
    },
    '&::after': {
      content: '" »"',
    },
  },
  '& hr': {
    margin: '40px 0',
  },
});

export const CardWrapper = styled.div({
  backgroundColor: colors.white,
  position: 'relative',
  borderRadius: 10,
  margin: '10px auto',
  //marginBottom: 10,
  width: 'calc(100% - 10px)',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px',
  transition: 'all 0.5s ease',
  ':hover': {
    boxShadow: 'rgba(39, 44, 49, 0.07) 8px 28px 50px, rgba(39, 44, 49, 0.04) 1px 6px 12px',
    transition: 'all 0.4s ease',
    transform: 'translate3D(0, -1px, 0) scale(1.01)',
  },
  [mediaQueries.tabletPortraitUp]: {
    width: 'calc(100% - 20px)',
    height: 'auto',
    margin: 10,
  },
  [mediaQueries.tabletLandscapeUp]: {
    width: 'calc(50% - 20px)',
  },
  [mediaQueries.desktopUp]: {
    width: 'calc(33.3333% - 20px)',
  },
});
