import React from 'react';
import colors from 'src/style/colors';
import { HeaderTitle } from '../Typography';
import { FooterText, Tag, CardContent, HeroPostImage, HeroCardContainer } from './elements';
import { Button } from '../Button';
import Theme from '../Theme';
import { mediaQueries } from 'src/style/breakpoints';
import ParagraphEllipsis from '../Typography/ParagraphEllipsis';

const HeroCard = ({ post }) => {
  return (
    <Theme theme="light">
      <HeroCardContainer to={post.fields.slug}>
        {post.frontmatter.featuredImage.childImageSharp.gatsbyImageData && (
          <div
            css={{
              [mediaQueries.tabletLandscapeUp]: {
                flex: 2.3,
              },
            }}
          >
            <HeroPostImage
              className="imagePreview"
              backgroundColor={colors.lightGrey}
              image={post.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
              alt={'image' + post.frontmatter.title}
            />
          </div>
        )}

        <CardContent hero>
          <Tag>{post.frontmatter.tags}</Tag>
          <HeaderTitle css={{ paddingBottom: 0, fontSize: 36, lineHeight: '46px' }}>
            {post.frontmatter.title}
          </HeaderTitle>
          <ParagraphEllipsis css={{ padding: 0 }} text={post.frontmatter.preview} hero />
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: 20,
              [mediaQueries.tabletLandscapeUp]: {
                paddingBottom: 20,
              },
            }}
          >
            <FooterText>{post.frontmatter.date}</FooterText>
            <div css={{ width: 30 }} />
            <FooterText>— {post.frontmatter.author}</FooterText>
          </div>

          <div>
            <Button isSmall to={post.fields.slug}>
              Lire l'article
            </Button>
          </div>
        </CardContent>
      </HeroCardContainer>
    </Theme>
  );
};
export default HeroCard;
