import React from 'react';
import colors from 'src/style/colors';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BlogPostTitle } from '../Typography';
import { Footer, FooterText, Tag, CardContent, CardWrapper, CardContainer } from './elements';
import LinesEllipsis from 'react-lines-ellipsis';
import CardOverlay from './CardOverlay';

const Card = ({ post, isBlogPost }) => {
  return (
    <CardWrapper>
      <CardContainer to={post.fields.slug}>
        <GatsbyImage
          className="imagePreview"
          css={{
            height: 245,
            position: 'relative',
          }}
          backgroundColor={colors.lightGrey}
          image={post.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
          alt="illustration de l'article de blog"
        />
        <CardContent>
          <Tag>{post.frontmatter.tags}</Tag>
          <BlogPostTitle>
            <LinesEllipsis
              text={post.frontmatter.title}
              css={{ color: colors.purpleDark }}
              maxLine="3"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </BlogPostTitle>
          <div css={{ height: 35 }} />

          <Footer>
            <FooterText>{post.frontmatter.date}</FooterText>
            <div css={{ width: 30 }} />
            <FooterText>— {post.frontmatter.author}</FooterText>
          </Footer>
        </CardContent>
        <CardOverlay post={post} isBlogPost={isBlogPost} />
      </CardContainer>
    </CardWrapper>
  );
};

export default Card;
